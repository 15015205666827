/* eslint-disable camelcase */
type FintocEventGTMLabels = {
  [key: string]: string;
  on_available_institutions: string;
  on_authentication_form: string;
  creating_link: string;
  selecting_account: string;
  creating_subscription: string;
  app_authentication_required: string;
  card_authentication_required: string;
  device_authentication_required: string;
  sms_authentication_required: string;
  email_authentication_required: string;
  captcha_authentication_required: string;
  on_error: string;
  opened: string;
};

const fintocEventGTMLabels: FintocEventGTMLabels = {
  on_available_institutions: 'SELECCIONAR INSTITUCION',
  on_authentication_form: 'INICIAR SESIÓN EN BANCO',
  creating_link: 'CREAR LINK DE INICIO DE SESIÓN',
  selecting_account: 'SELECCIÓN DE CUENTA',
  creating_subscription: 'CREANDO SUSCRIPCION',
  app_authentication_required: 'AUTENTICACION MEDIANTE APP',
  card_authentication_required: 'AUTENTICACION MEDIANTE TARJETA COORDENADAS',
  device_authentication_required: 'AUTENTICACION MEDIANTE DISPOSITIVO',
  sms_authentication_required: 'AUTENTICACION MEDIANTE SMS',
  email_authentication_required: 'AUTENTICACIÓN MEDIANTE EMAIL',
  captcha_authentication_required: 'AUTENTICACIÓN MEDIANTE CAPTCHA',
  on_error: 'ERROR CONEXION FINTOC',
  opened: 'BIENVENIDA',
  validating_second_factor: 'VALIDANDO SEGUNDO FACTOR DE AUTENTICACION',
  subscription_created: 'PAC CONFIGURADO EXITOSAMENTE',
  on_terms_and_conditions: 'VISITANDO TERMINOS Y CONDICIONES FINTOC',
  on_request_bank: 'SOLICITANDO NUEVA INTEGRACION CON BANCO',
  subscription_aborted: 'INTENTO SUSCRIPCION ABORTADA',
};

const getFintocGTMEventLabel = (value: string) => {
  if (fintocEventGTMLabels[value]) {
    return fintocEventGTMLabels[value];
  }
  return undefined;
};

export default getFintocGTMEventLabel;
