import React from 'react';
import {
  Divider,
  Fade,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import bancameLogoDark from '../../../assets/bancame_logo_dark.svg';
import uafLogo from '../../../assets/uaf.svg';
import getPartnerButton from '../../../components/PartnerButtons';
import TermsAndConditionsLink from '../../../components/TermsAndConditionsLink';
import { Partner } from '../../../components/LeadFormIndex/types';
import analytics, { resetAnalytics } from '../../../utils/analytics';

type PLRWelcomeProps = {
  amount: string,
  periods: string,
  handleSetShowForm: (show:boolean)=> void,
  origin: string,
  partner: Partner,
}

export default function PLRWelcome(props:PLRWelcomeProps) {
  const {
    amount, periods, handleSetShowForm, origin, partner,
  } = props;
  const theme = useTheme();
  const [checked, setChecked] = React.useState(false);
  const [logo, setLogo] = React.useState<string | null>(null);
  const [footerLogo, setFooterLogo] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const handleSetChecked = () => setChecked((o) => !o);

  const getText = () => {
    if (partner.partnerProducts.some((product) => product === 'mortgage') && origin !== 'goplaceit' && origin !== 'IRentup') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación crediticia para descubrir si pre-calificas
          a un crédito hipotecario.
        </b>
      );
    }
    if (partner.partnerProducts?.some((product) => product === 'mortgage') && origin === 'goplaceit') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación crediticia para descubrir si pre-calificas
          a un crédito pie.
        </b>
      );
    }
    if (partner.partnerProducts?.some((product) => product === 'microLoan') && origin === 'tenpo') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación para descubrir si pre-calificas
          a un adelanto.
        </b>
      );
    }
    if (partner.partnerProducts?.some((product) => product === 'microLoan') && origin === 'smartycar') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación automática.
        </b>
      );
    }
    if (partner.partnerProducts.some((product) => product === 'mortgage') && origin === 'IRentup') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación para arriendo.
        </b>
      );
    }
    return (
      <b style={{ color: theme.palette.text.primary }}>
        solicitud de un crédito responsable con nosotros.
      </b>
    );
  };

  const getFooter = () => {
    if (partner.partnerName !== 'Banca.Me' && partner.partnerConfiguration.headerLogo) {
      return bancameLogoDark;
    }
    return footerLogo;
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLogo(partner.partnerConfiguration.headerLogo || null);
      setFooterLogo(partner.partnerConfiguration.footerLogo);
      setLoading(false);
    };
    fetchData();
  }, [partner.partnerConfiguration]);
  React.useEffect(() => {
    resetAnalytics();
    analytics.page('PLR - ONBOARDING', 'BIENVENIDA');
  }, []);

  return (
    <Fade
      in
    >
      <div style={{
        backgroundColor: theme.palette.background.default,
        height: '100%',
        display: 'grid',
        alignItems: 'center',
      }}
      >
        <div>
          {loading ? <div style={{ width: '300px', height: '80px', margin: 'auto' }} />
            : (
              <div style={{ width: '300px', height: '80px', margin: 'auto' }}>
                <object
                  data={logo || bancameLogoDark}
                  aria-labelledby="Bancame Logo"
                  style={{
                    background: origin === 'rentaCapital' ? '#071c35' : '',
                    padding: '0.5em',
                    width: '100%',
                    height: 'auto',
                    maxWidth: '300px',
                    maxHeight: '80px',
                  }}
                />
              </div>
            )}
          <Typography
            data-pw="preloan-welcome-header"
            variant="h3"
            fontWeight="light"
            style={{ marginInline: 'auto', marginBlock: '1%' }}
          >
            ¡Te damos la bienvenida!
          </Typography>

          <br />
          <div style={{ maxWidth: 800 }}>
            <Divider />
            <Typography
              variant="body1"
              data-pw="preloan-welcome-text"
              style={{
                padding: '3%',
                lineHeight: '1.8',
                backgroundColor: partner.partnerName === 'Bricsa'
                  ? theme.palette.background.default
                  : theme.palette.background.defaultSecondary,
              }}
            >
              {amount
                ? (
                  <>
                    ¡Hola!
                    {' '}
                    Estás a punto de comenzar la
                    {' '}
                    <b style={{ color: theme.palette.text.primary }}>
                      solicitud de un crédito
                      responsable de
                      {` $${(+amount).toLocaleString('es-CL')}`}
                    </b>
                    {periods
                      ? (
                        <>
                          , en un plazo de
                          {` ${(+periods)} meses. `}
                        </>
                      ) : '. '}
                  </>
                ) : (
                  <>
                    ¡Hola! Estás a punto de comenzar una
                    {' '}
                    {getText()}
                    {' '}
                  </>
                )}
              <span>
                Te pediremos algunos datos para evaluar tu situación, como
              </span>
              {' '}
              {(['talana', 'smartycar', 'IRentup'].includes(origin)
            || partner.partnerProducts.some((product) => product === 'mortgage'))
                ? (
                  <b style={{ color: theme.palette.text.primary }}>
                    tu RUT y ClaveÚnica.
                  </b>
                ) : (
                  <b style={{ color: theme.palette.text.primary }}>
                    tu RUT,
                    tu última liquidación de sueldo y finalmente tu ClaveÚnica,
                    o tus últimas 12 cotizaciones de AFP.
                  </b>
                )}
            </Typography>
            <Divider />
            <br />
            <TermsAndConditionsLink
              origin={origin}
              checked={checked}
              handleSetChecked={handleSetChecked}
            />
          </div>
          <Typography
            variant="body2"
            style={{ padding: '2.5%', maxWidth: 500, margin: 'auto' }}
          >

            Por favor acepta los términos y condiciones, la política de privacidad y haz click en
            {' '}
            <span style={{ fontWeight: 'bold' }}>Comenzar</span>
            {' '}
            para continuar
          </Typography>
          {getPartnerButton({
            partner,
            disabled: !checked,
            onClick: () => handleSetShowForm(true),
            buttonLabel: 'Comenzar',
            endIcon: <ArrowForwardIcon color="secondary" />,
            testingLabel: 'preloan-welcome-button',
          })}
          {(partner.partnerName !== 'Banca.Me' && (partner.partnerConfiguration.footerLogo || partner.partnerConfiguration.headerLogo))
                  && (
                  <Typography
                    variant="body1"
                    style={{
                      margin: '15px', display: 'flex', placeContent: 'center', color: 'gray',
                    }}
                    fontStyle="italic"
                  >
                    {'Alianza con '}
                    {loading ? <span>Cargando...</span>
                      : (
                        <object
                          style={{ marginLeft: 5 }}
                          data={getFooter()}
                          width="80px"
                          height="22px"
                          aria-labelledby="Provider Logo"
                        />
                      )}
                  </Typography>
                  ) }
          <Typography
            variant="body2"
            fontStyle="italic"
            style={{
              padding: '2.5%',
              marginLeft: '1em',
              marginRight: '1em',
            }}
          >

            Estamos regulados por la
            <a href="https://www.uaf.cl">
              <object
                style={{ marginLeft: 5, marginTop: '10%' }}
                data={uafLogo}
                width="100px"
                aria-labelledby="Provider Logo"
              />
            </a>
          </Typography>
        </div>
      </div>
    </Fade>
  );
}
