import React from 'react';
import { ReactSession } from 'react-client-session';
import { formatRut } from 'react-rut-formatter';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import { demoUserReport } from './demoData';
import type {
  LoanData,
  ReferralsData,
  PrepayRequestSimulationData,
  PrepayRequestData,
  UserReportsData,
  UserReportData,
  UserQuoteBalance,
  postFintocSubscriptionIntentResponse,
  getFintocSubscriptionResponse,
  postExternalSubscriptionIntentResponse,
  getExternalSubscriptionResponse,
} from '../../types/account';
import { ClaveUnicaEndpointProps } from '../../components/ClaveUnicaForm';

export function getLoan(token:string, condition?: boolean) {
  const [loanData, setLoanData] = React.useState<LoanData['data']|null>(null);
  const { data, error, isLoading }: {data: LoanData, error?: boolean, isLoading: boolean} = useSWR(condition ? [`${process.env.REACT_APP_API_URL}/loan`, 'GET', null, token] : null, ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      setLoanData(data.data);
    }
  }, [data]);
  return {
    loanData,
    loanDataLoading: isLoading,
    loanDataError: error,
  };
}

export function getInvestment(token:string) {
  const [quoteBalance, setQuoteBalance] = React.useState<UserQuoteBalance['data']|null>(null);
  const { data, error, isLoading }: {data: UserQuoteBalance, error?: boolean, isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/quote/balance`, 'GET', null, token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      setQuoteBalance(data.data);
    }
  }, [data]);
  return {
    quoteBalance,
    quoteDataLoading: isLoading,
    quoteDataError: error,
  };
}

export function getLastPrepayRequests(token:string) {
  const [lastPrepayRequestsData,
    setLastPrepayRequestData] = React.useState<PrepayRequestData[]>([]);
  const [loading, setLoading] = React.useState(true);
  const {
    data,
    error,
  }:{data:
    {data: PrepayRequestData[], status: number}, error?: boolean, isLoading: boolean} = useSWR(
      [`${process.env.REACT_APP_API_URL}/client/prepay-request`,
        'GET',
        null,
        token],
      ([url, method, body]) => fetcher(url, method, body, token),
    );
  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setLastPrepayRequestData(data.data);
    }
    setLoading(false);
  }, [data]);
  return {
    lastPrepayRequestsData,
    lastPrepayRequestDataLoading: loading,
    lastPrepayRequestDataError: error,
  };
}

export function getReferralInfo(token: string) {
  const [referralsData, setReferralsData] = React.useState<ReferralsData['data']|null>(null);
  const { data, error, isLoading }: {data: ReferralsData, error?: boolean, isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/client/referral/info`, 'GET', null, token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      setReferralsData(data.data);
    }
  }, [data]);
  return {
    referralsData,
    referralsDataLoading: isLoading,
    referralsDataError: error,
  };
}

export async function postSignUp(
  idNumber: string,
  firstName: string,
  lastName: string,
  email: string,
) {
  ReactSession.setStoreType('sessionStorage');
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/sign-up/identification`,
    'POST',
    {
      idNumber: formatRut(idNumber), firstName, lastName, email,
    },
    null,
  );
  ReactSession.set('User', JSON.stringify({
    clientToken: res.data, firstName, email, idNumber,
  }));
}
export async function postSimulatePrepayRequest(token: string, loanId: string) {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/client/prepay-request/simulate`,
    'POST',
    { type: 'fullPrepay', loanId },
    token,
  ) as PrepayRequestSimulationData;
  return {
    unpaidCapital: res.data.unpaidCapital,
    delinquencyDebt: res.data.delinquencyDebt,
    interestAmount: res.data.interestAmount,
    prepayComission: res.data.prepayComission,
    totalToDeposit: res.data.totalToDeposit,
  };
}

export async function postCreatePrepayRequest(token: string, loanId: string) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/client/prepay-request`,
    'POST',
    {
      prepayType: 'fullPrepay',
      motive: 'SOLICITUD DE USUARIO',
      loanId,
    },
    token,
  );
}

export async function deletePrepayRequest(token: string, prepayRequestId: string) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/client/prepay-request`,
    'DELETE',
    {
      prepayRequestId,
    },
    token,
  );
}

export async function postRecoverPassword(email: string) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/password/recover`,
    'POST',
    { email },
    null,
  );
}

export async function postResetPassword(password: string, clientToken: string) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/password/reset`,
    'POST',
    { password },
    clientToken,
  );
}

export async function postSignUpSetPassword(password: string, clientToken: string) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/sign-up/set-password`,
    'POST',
    { password },
    clientToken,
  );
}

export async function postClientVerify(
  idNumber: string,
  email:string,
  clientToken: string,
  tokenIntention: 'verifyAccount'|'userReport',
) {
  const RutFormatted = formatRut(idNumber).toLowerCase();
  await fetcher(
    `${process.env.REACT_APP_API_URL}/verify/client`,
    'POST',
    { rut: RutFormatted, email, tokenIntention },
    clientToken,
  );
}

export async function patchClientOptIn(token: string) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/client/opt-in`,
    'PATCH',
    { optIn: true },
    token,
  );
}

export function getUserReports(token: string) {
  const [userReportsData, setUserReportsData] = React.useState<UserReportsData['data']|null>(null);
  const { data, error, isLoading }: {data: UserReportsData, error?: boolean, isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/user-reports`, 'GET', null, token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      setUserReportsData(data.data);
    }
  }, [data]);
  return {
    userReportsData,
    userReportsDataLoading: isLoading,
    userReportsDataError: error,
  };
}
export async function getUserReportsOnce(token:string) {
  const data = await fetcher(
    `${process.env.REACT_APP_API_URL}/user-reports`,
    'GET',
    null,
    token,
  ) as UserReportsData;
  return {
    userReportsData: data.data,
  };
}

export async function postCheckIsClaveUnicaUpdated(token:string, isUserReport?: boolean) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/clave-unica-history/check`,
    'POST',
    { isUserReport: !!isUserReport },
    token,
  );
}

export function getUserReport(token: string, id: string) {
  if (id === 'demo') {
    return {
      userReport: demoUserReport,
      userReportLoading: false,
      userReportError: false,
    };
  }
  const [userReport, setUserReport] = React.useState<UserReportData['data']['report']|null>(null);
  const { data, error, isLoading }: {data: UserReportData, error?: boolean, isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/user-report/${id}`, 'GET', null, token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      setUserReport(data.data.report);
    }
  }, [data]);
  return {
    userReport,
    userReportLoading: isLoading,
    userReportError: error,
  };
}

export async function postPreLoanRequestRefinance(token: string, type: 'refinanceRP' | 'cashout') {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/refinance`,
    'POST',
    { type },
    token,
  );
  // Correct in PLR V2.5 refactor
  return res as {data:{companyId:string,
    debts:[],
    firstame:string,
    lastName:string,
    hardFilterResults:{},
    hasQuoteCertificate:boolean,
    hasSettlement:boolean,
    hasUploadedCompanyInfo:boolean,
    idNumber:string,
    origin: 'refinanceRP'|'cashout',
    phone: string,
    step: 'READY_fOR_LOAN'
  }};
}

export async function postClaveUnicaHistory(
  params: ClaveUnicaEndpointProps,
  isUserReport?: boolean,
) {
  const { token, password } = params;
  return fetcher(
    `${process.env.REACT_APP_API_URL}/clave-unica-history`,
    'POST',
    { password, isUserReport: !!isUserReport },
    token,
  );
}

export async function postFintocSubscription(token: string):
Promise<postFintocSubscriptionIntentResponse> {
  return fetcher(`${process.env.REACT_APP_API_URL}/external-subscription/fintoc/intent`, 'POST', null, token);
}
export async function getFintocSubscription(token: string):
Promise<getFintocSubscriptionResponse> {
  return fetcher(`${process.env.REACT_APP_API_URL}/external-subscription`, 'GET', null, token);
}

export async function postExternalSubscriptionIntent(token: string, paymentMethod: 'fintoc' | 'etpay'):
Promise<postExternalSubscriptionIntentResponse> {
  return fetcher(
    `${process.env.REACT_APP_API_URL}/external-subscription/${paymentMethod}/intent`,
    'POST',
    null,
    token,
  );
}

export async function getExternalSubscription(token: string):
Promise<getExternalSubscriptionResponse> {
  return fetcher(
    `${process.env.REACT_APP_API_URL}/external-subscription`,
    'GET',
    null,
    token,
  );
}
