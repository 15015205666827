import React from 'react';
import useIframeMessage from './useIframeMessage';

interface MyIframeComponentProps {
  widgetToken: string;
  onClose: () => void;
  onSuccess: () => void;
  onError: (error: string) => void;
}

export default function MyIframeComponent({
  widgetToken,
  onClose,
  onSuccess,
  onError,
}: MyIframeComponentProps) {
  const iframeOrigin = 'https://dd-sandbox.etpay.com';
  const { message, error, status } = useIframeMessage(iframeOrigin);
  const iframeRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(true);

  const closeIframe = () => {
    setIsOpen(false);
    if (iframeRef.current) {
      (iframeRef.current as HTMLIFrameElement).src = 'about:blank';
    }
  };

  React.useEffect(() => {
    if (status) {
      switch (status) {
        case 'SUCCESS':
          onSuccess();
          closeIframe();
          break;
        case 'ERROR':
          onError(message || '');
          closeIframe();
          break;
        case 'CLOSE':
          closeIframe();
          onClose();
          break;
        default:
          break;
      }
    }

    if (error) {
      onError(error);
      closeIframe();
      onClose();
    }
  }, [status, error, message]);

  if (!isOpen) return null;

  return (
    <iframe
      src={`https://dd-sandbox.etpay.com/session/${widgetToken}`}
      id="etpayWidget"
      title="Etpay Widget"
      ref={iframeRef}
      style={{
        borderWidth: '0px',
        borderRadius: '10px',
        width: window.innerWidth < 686 + 20 ? window.innerWidth - 20 : 686,
        height: window.innerHeight < 708 + 100 ? window.innerHeight - 100 : 708,
      }}
    />
  );
}
