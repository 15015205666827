import React from 'react';
import {
  CircularProgress,
  useTheme,
  Backdrop,
  Typography,
  Divider,
  Card,
  Button,
} from '@mui/material';
import EtPayPayment from './Etpay/EtPayPayment';
import FintocPayment from './Fintoc/FintocPayment';
import analytics from '../../utils/analytics';
import {
  postExternalSubscriptionIntent,
  getExternalSubscription,
} from '../../queries/account';
import fintocLogo from '../../assets/fintoc.svg';
import etpayLogo from '../../assets/etpay.svg';
import BancameAlert from '../Alert';
import useStyles from './styles';

interface PaymentWidgetProps {
  token: string;
  idNumber: string;
  paymentMethodProvider: 'fintoc' | 'etpay';
}

const notSubscribedStates = ['INTENT', 'CANCELED'];

const PAYMENT_PROVIDERS = {
  fintoc: FintocPayment,
  etpay: EtPayPayment,
};

export default function PaymentWidget({
  token,
  idNumber,
  paymentMethodProvider,
}: PaymentWidgetProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [paymentMethod, setPaymentMethod] = React.useState<'fintoc' | 'etpay'>(paymentMethodProvider);
  const [isAlreadySubscribed, setIsAlreadySubscribed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [widgetToken, setWidgetToken] = React.useState<string | undefined>(
    undefined,
  );
  const [openAlert, setOpenAlert] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(
    '¡Pago (PAC) configurado correctamente!',
  );
  const [errorMessage, setErrorMessage] = React.useState(
    'Ha ocurrido un error, por favor contáctanos',
  );

  const [enableChangePaymentMethod, setEnableChangePaymentMethod] = React.useState(false);

  const suscriptionIntent = async () => {
    setOpenAlert(false);
    if (!paymentMethod) {
      setError(true);
      setOpenAlert(true);
      return false;
    }
    setLoading(true);
    setError(false);
    try {
      const res = await postExternalSubscriptionIntent(token, paymentMethod);
      setLoading(false);
      if (!notSubscribedStates.includes(res.subscriptionStatus)) {
        setIsAlreadySubscribed(true);
        analytics.page('PU - PAGOS', 'PAC YA CONFIGURADO');
        return true;
      }
      analytics.page('PU - PAGOS', 'CONFIGURACION PAC');
      setWidgetToken(res.widgetToken);
      return true;
    } catch (e) {
      setError(true);
      setLoading(false);
      setOpenAlert(true);
      return false;
    }
  };

  const checkSubscriptionStatus = async () => {
    setError(false);
    setLoading(true);
    setOpenAlert(false);

    let retriesCount = 0;
    const MAX_RETRIES = 15;

    const delay = (ms: number) => new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

    const checkSubscription = async (): Promise<boolean> => {
      try {
        const res = await getExternalSubscription(token);
        if (!notSubscribedStates.includes(res.subscriptionStatus)) {
          setLoading(false);
          setIsAlreadySubscribed(true);
          setSuccessMessage('¡Pago (PAC) configurado correctamente!');
          setError(false);
          setOpenAlert(true);
          return true;
        }

        retriesCount += 1;
        if (retriesCount < MAX_RETRIES) {
          await delay(2000);
          return await checkSubscription();
        }
        throw new Error('Timeout checking subscription status');
      } catch (e) {
        setLoading(false);
        setErrorMessage('Ha ocurrido un error, por favor contáctanos');
        setError(true);
        setOpenAlert(true);
        return false;
      }
    };

    return checkSubscription();
  };

  const changePaymentMethod = () => {
    setPaymentMethod(paymentMethod === 'fintoc' ? 'etpay' : 'fintoc');
    setEnableChangePaymentMethod(false);
  };

  const handleError = (message: string) => {
    setOpenAlert(false);
    setSuccessMessage(message);
    setError(true);
    setOpenAlert(true);
    setEnableChangePaymentMethod(true);
  };

  const handleSuccess = async () => {
    const response = await checkSubscriptionStatus();
    if (!response) {
      setEnableChangePaymentMethod(true);
    }
  };

  const onExit = () => {
    setOpenAlert(false);
    setSuccessMessage(
      'Si no configuras PAC, no podremos cobrar automaticamente tu producto',
    );
    setError(false);
    setOpenAlert(true);
  };

  React.useEffect(() => {
    if (token && paymentMethod && !isAlreadySubscribed) {
      suscriptionIntent();
    }
  }, [token, paymentMethod]);

  const PaymentProvider = paymentMethod
    ? PAYMENT_PROVIDERS[paymentMethod]
    : PAYMENT_PROVIDERS.fintoc;

  return (
    <Card
      style={{
        borderRadius: '20px',
      }}
    >
      <div style={{ marginBottom: paymentMethod === 'fintoc' ? 10 : 0, textAlign: 'center', marginTop: paymentMethod === 'fintoc' ? 20 : 15 }}>
        <object
          className={paymentMethod === 'fintoc' ? classes.fintocLogo : classes.etpayLogo}
          data={paymentMethod === 'fintoc' ? fintocLogo : etpayLogo}
          aria-labelledby={paymentMethod === 'fintoc' ? 'fintoc logo' : 'etpay logo'}
        />
      </div>
      <Divider />
      <div className={classes.content}>
        {!isAlreadySubscribed ? (
          <div style={{ margin: 20 }}>
            <Typography variant="body2" align="center" gutterBottom>
              Estimado usuario, para acceder a nuestros productos de
              financiamiento es necesario
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>
                suscribir un PAC (Pago Automático de Cuentas)
              </b>
              {' '}
              para recibir tu crédito.
            </Typography>
            <Typography variant="body2" align="center">
              Debes tener a mano tus credenciales bancarias (e.g. tarjeta de
              coordenadas o digipass). Haz click en
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>Asociar PAC</b>
              {' '}
              para comenzar el proceso.
            </Typography>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <PaymentProvider
                widgetToken={widgetToken}
                idNumber={idNumber}
                onError={handleError}
                onSuccess={handleSuccess}
                onExit={onExit}
              />
            </div>

            {enableChangePaymentMethod && (
              <div style={{
                justifyContent: 'center', display: 'flex', paddingBlock: 10,
              }}
              >
                <Button onClick={changePaymentMethod} variant="outlined" color="secondary" size="small">Cambiar proveedor de pago</Button>
              </div>
            )}

          </div>
        ) : (
          <div style={{ margin: 20 }}>
            <Typography variant="body2" align="center" gutterBottom>
              Estimado usuario,
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>
                ¡ya tienes tu PAC configurado!
              </b>
            </Typography>
            <Typography variant="body2" align="center">
              Ya estas listo por ahora
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>
                ¡solo queda seguir disfrutando nuestros productos!
              </b>
            </Typography>
          </div>
        )}
        <BancameAlert
          successMessage={successMessage}
          errorMessage={errorMessage}
          openAlert={openAlert}
          error={error}
        />
        <Backdrop className={classes.backdrop} open={loading}>
          <div>
            <CircularProgress color="inherit" />
          </div>
          <b style={{ marginLeft: 20 }}>Cargando, espera un momento...</b>
        </Backdrop>
      </div>
    </Card>
  );
}
