import React from 'react';
import getProducts from '../../queries/products';
import AccountDataLoading from '../../components/DataLoading';
import ProductsOverview from '../../feature/products/ProductsOverview';
import type { UserAccountComponent } from '../../types/account';

export default function ProductsView(props: UserAccountComponent) {
  const { token } = props;
  const { productsData, productsDataLoading, productsDataError } = getProducts();

  return (
    <>
      {(productsDataLoading || productsDataError) && (
        <AccountDataLoading
          paddingTop="15%"
          firstText="Cargando..."
          error={productsDataError}
        />
      )}
      {!productsDataError && !productsDataLoading && productsData && (
        <ProductsOverview token={token} productsData={productsData} />
      )}
    </>
  );
}
