import React from 'react';
import {
  Grid,
} from '@mui/material';
import jwtDecode from '../../../utils/jwtDecode';
import type { UserAccountComponent } from '../../../types/account';
import ViewTitle from '../../../components/ViewTitle';
import useStyles from '../styles';
import PaymentWidget from '../../../components/PaymentWidget';

export default function PacView(props: UserAccountComponent) {
  const { token } = props;
  const { idNumber } = jwtDecode(token);
  const classes = useStyles();
  const defaultPaymentMethodProvider = process.env.REACT_APP_DEFAULT_PAYMENT_METHOD_PROVIDER as 'fintoc' | 'etpay';
  return (
    <Grid
      container
      style={{ height: '100%' }}
    >
      <div className={classes.contentContainer}>

        <ViewTitle
          title="Pagos"
          color="secondary"
          variant="h3"
          subtitle="En esta sección podrás validar que tu Pago (PAC) esta activo, o en su defecto asociar el correspondiente."
        />
        <Grid
          container
          rowGap={10}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            md={5}
            margin={15}
          >
            <PaymentWidget
              idNumber={idNumber}
              token={token}
              paymentMethodProvider={defaultPaymentMethodProvider}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
