import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import JWTDecode from '../../utils/jwtDecode';
import { useQuery } from '../../utils/helpers';
import PreLoanRequestIndexV3 from '../../feature/preloan/v3';
import { getOriginPartner, patchOriginClick } from '../../queries/preloan/v3';
import LeadFormIndex from '../../components/LeadFormIndex';
import type { Origin, Partner, PreLoanRequestTokenType } from '../../components/LeadFormIndex/types';
import analytics from '../../utils/analytics';

export default function PreLoanIndex() {
  const query = useQuery();
  const history = useHistory();
  const {
    boot: bootIntercom,
    shutdown: shutdownIntercom,
  } = useIntercom();
  const searchParams = React.useMemo(() => query, [query]);
  const [loading, setLoading] = React.useState(false);
  const [preLoanId, setPreLoanId] = React.useState('');
  const [showForm, setShowForm] = React.useState(false);
  const [error, setError] = React.useState(false);
  const defaultOriginClickRes = { originLabel: 'web' };
  const defaultOriginPartner = { partnerName: 'Banca.Me', partnerProducts: ['consumer, microLoan'], partnerConfiguration: {} };
  const [origin, setOrigin] = React.useState<Origin|undefined>(undefined);
  const [partner, setPartner] = React.useState<Partner|undefined>(undefined);

  const navigation = window.performance.getEntriesByType('navigation')[0].toJSON().type;

  const handleSetShowForm = (show: boolean) => {
    setShowForm(show);
  };

  const originClickAndPartner = async (params:{originIcomingData: string,
    click: boolean,
    token:string|null}) => {
    const { originIcomingData, click, token } = params;
    let originData: { originLabel: string } = defaultOriginClickRes;
    let partnerData: { partnerName: string, partnerProducts: string[],
      partnerConfiguration: {} } = defaultOriginPartner;
    try {
      setLoading(true);
      const res = await patchOriginClick(originIcomingData, click && !token);
      originData = res.data;
    } catch (e) {
      originData = defaultOriginClickRes;
    }
    try {
      const res = await getOriginPartner(originData.originLabel);
      partnerData = res.data;
    } catch (e) {
      partnerData = defaultOriginPartner;
    }
    setLoading(false);
    searchParams.set('origin', originData.originLabel);
    searchParams.set('partner', partnerData.partnerName);

    if (token) {
      searchParams.set('token', token);
    }

    if (partnerData.partnerName === 'Banca.Me') {
      bootIntercom();
    } else {
      shutdownIntercom();
    }
    history.push({
      search: searchParams.toString(),
    });
    setOrigin(originData);
    setPartner(partnerData);
    if (token) {
      setShowForm(true);
    }
    setLoading(false);
    return null;
  };

  React.useEffect(() => {
    const token = query.get('token');
    let originIcomingData = query.get('origin') || 'web';

    if (token) {
      setLoading(true);
      try {
        const payload: PreLoanRequestTokenType = JWTDecode(token);
        setPreLoanId(payload.preLoanRequestId);
        originIcomingData = payload.originLabel;
        analytics.identify(payload.identificationId, {
          plrId: payload.preLoanRequestId,
        });
      } catch (e) {
        setError(true);
      }
    }
    originClickAndPartner({ originIcomingData, click: navigation === 'navigate', token });
  }, [query.get('origin')]);

  return (
    <LeadFormIndex
      partner={partner}
      error={error}
      loading={loading}
    >
      {!loading && origin
        ? (
          <PreLoanRequestIndexV3
            handleShowForm={handleSetShowForm}
            preLoanId={preLoanId}
            showForm={showForm}
            origin={origin}
            partner={partner ?? defaultOriginPartner}
          />
        ) : <div />}
    </LeadFormIndex>

  );
}
