import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { Breakpoint, Divider } from '@mui/material';
import useStyles from './styles';
import analytics from '../../utils/analytics';
import bancameLogo from /* webpackPreload: true */ '../../assets/bancame_logo_dark.svg';

type AlertDialogProps = {
  buttonLabel?: string;
  dialogTitle?: string | React.ReactNode;
  dialogContent: string | React.ReactNode;
  action?: () => Promise<void> | void;
  onCloseAction?: () => void;
  button?: React.ReactElement | null;
  buttonVariant?: 'outlined' | 'text' | 'contained' | undefined;
  isTriggered?: boolean;
  triggerClose?: boolean;
  hideCloseButton?: boolean;
  closeButtonDisabled?: boolean;
  closeButtonLabel?: string | React.ReactNode;
  submitButtonLabel?: string;
  submitButtonDisabled?: boolean;
  centerButtons?: boolean;
  closeAfterSubmit?: boolean;
  hideSubmitButton?: boolean;
  maxWidth?: Breakpoint;
  alternativeBackground?: boolean;
  buttonMaxWidth?: number;
  disabled?: boolean;
  pageCall?: {
    name: string;
    category: string;
  };
  keepMounted?: boolean;
  hideLogo?: boolean;
  hideActions?: boolean;
  hideTitle?: boolean;
  dialogContentStyle?: React.CSSProperties;
};
const defaultProps = {
  button: null,
  buttonLabel: '',
  buttonVariant: 'text',
  dialogTitle: '',
  hideCloseButton: false,
  closeButtonDisabled: false,
  closeButtonLabel: 'CERRAR',
  submitButtonLabel: 'Continuar',
  submitButtonDisabled: false,
  centerButtons: false,
  isTriggered: false,
  triggerClose: false,
  closeAfterSubmit: false,
  onCloseAction: () => {},
  action: () => {},
  hideSubmitButton: false,
  maxWidth: 'sm',
  alternativeBackground: false,
  buttonMaxWidth: 220,
  disabled: false,
  pageCall: undefined,
  keepMounted: false,
  hideLogo: false,
  hideActions: false,
  hideTitle: false,
  dialogContentStyle: {},
};
export default function AlertDialog(props: AlertDialogProps) {
  const {
    buttonLabel,
    dialogTitle,
    dialogContent,
    button,
    buttonVariant,
    isTriggered,
    triggerClose,
    hideCloseButton,
    closeButtonDisabled,
    closeButtonLabel,
    submitButtonLabel,
    submitButtonDisabled,
    centerButtons,
    closeAfterSubmit,
    onCloseAction = defaultProps.onCloseAction,
    action = defaultProps.action,
    hideSubmitButton,
    maxWidth,
    alternativeBackground,
    buttonMaxWidth,
    disabled,
    pageCall,
    keepMounted,
    hideLogo,
    hideActions,
    hideTitle,
    dialogContentStyle,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    if (pageCall) {
      analytics.page(pageCall.category, pageCall.name);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    analytics.track('Cerrar Dialog', {
      initiator: 'usuario',
    });
    onCloseAction();
  };
  React.useEffect(() => {
    if (isTriggered) {
      handleClickOpen();
    }
  }, [isTriggered]);
  React.useEffect(() => {
    if (triggerClose) {
      setOpen(false);
    }
  }, [triggerClose]);

  const onSubmit = async () => {
    setLoading(true);
    await action();
    setLoading(false);
    if (closeAfterSubmit) {
      setOpen(false);
    }
  };

  return (
    <div>
      {button ? (
        React.cloneElement(button, {
          onClick: !disabled ? handleClickOpen : undefined,
        })
      ) : (
        <Button
          variant={buttonVariant}
          style={{ maxWidth: buttonMaxWidth }}
          onClick={handleClickOpen}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      )}
      <Dialog
        className={classes.Dialog}
        maxWidth={maxWidth}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        keepMounted={keepMounted}
      >
        {!hideLogo && (
          <>
            <div style={{ marginBottom: 10, textAlign: 'center', marginTop: 20 }}>
              <object data={bancameLogo} width="140px" aria-labelledby="banca.me logo" />
            </div>
            <Divider />
          </>
        )}
        {!hideTitle && (
          <>
            <br />
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          </>
        )}
        <DialogContent
          className={alternativeBackground ? classes.DialogContentAlt : undefined}
          style={dialogContentStyle}
        >
          <DialogContentText id="alert-dialog-description" component="div">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        {!hideActions && (
          <DialogActions style={{ margin: centerButtons ? 'auto' : undefined }}>
            {!hideCloseButton && (
              <Button disabled={loading || closeButtonDisabled} onClick={handleClose}>
                {closeButtonLabel}
              </Button>
            )}
            {!hideSubmitButton && (
              <Button
                disabled={loading || submitButtonDisabled}
                variant="contained"
                color="primary"
                onClick={onSubmit}
                style={{ maxWidth: 150 }}
              >
                {loading ? <CircularProgress size={15} /> : <span>{submitButtonLabel}</span>}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

AlertDialog.defaultProps = defaultProps;
