import { useState, useEffect } from 'react';

function useIframeMessage(iframeOrigin: string) {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | undefined>(undefined);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== iframeOrigin) {
        setError('Origen del mensaje no válido');
        return;
      }

      try {
        const { data } = event;
        if (!data.status) {
          setError('No hay datos en el mensaje');
          return;
        }
        setMessage(data.message);
        setStatus(data.status);
      } catch (err) {
        setError('Error al procesar el mensaje');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [iframeOrigin]);

  return { message, error, status };
}

export default useIframeMessage;
